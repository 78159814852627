<template>
    <fragment>
        <div class="page-title dashboard">
            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <div class="page-title-content">
                            <p>{{ $t('wallet.title1') }}
                                <span> {{ user.username }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <ul class="text-right breadcrumbs list-unstyle">
                            <li><a href="/profile/settings">{{ $t('wallet.link1') }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <section class="userCard">
            <div class="userCard__container">
                <div class="userCard__box">
                    <div class="userCard__item-left">
                        <div class="userCard__user-data">
                            <div class="userCard__img-box">
                                <img :src="user.avatar ? user.avatar : require('../../assets/img/profile/avatar.svg')"
                                    alt="">
                            </div>

                            <div class="userCard__user-data-box">
                                <div class="userCard__user-name">{{ user.username }}</div>
                                <div class="userCard__user-email">{{ user.email.substring(0, 17) }}...</div>
                                <div class="userCard__user-id">ID: {{ user.userid }}</div>
                            </div>
                        </div>
                        <div class="userCard__balance">
                            <div class="userCard__balance-title">{{ $t('wallet.title2') }}</div>
                            <div class="userCard__balance-count">{{ balance.available | numeralFormat('0,0.00') }}
                                <span>USD</span>
                            </div>
                        </div>
                    </div>

                    <div class="userCard__item-right">
                        <div class="userCard__promo-title">{{ $t('wallet.title3') }}</div>
                        <form>
                            <label>
                                <input v-model="promocode" class="userCard__promo-input" type="text"
                                    :placeholder="$t('wallet.text1')">
                            </label>
                            <button @click="activatePromo()" type="button" class="userCard__promo-btn">
                                {{ $t('wallet.btn1') }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <section class="userNavigation">
            <div class="userCard__container">
                <div class="transfer__info userNavigation__box">
                    <div>
                        <div class="transfer__title">
                            {{ active.page == 0 ? $t('wallet.text2') : active.page == 1 ? $t('wallet.text3') :
                                $t('wallet.text4') }}
                        </div>
                        <div class="transfer__description">
                            {{ active.page == 0 ? $t('wallet.text5') : active.page == 1 ? $t('wallet.text6') :
                                $t('wallet.text7') }}
                        </div>
                    </div>
                    <div class="userNavigation__tabBtn-box">
                        <a @click="openDeposit">
                            <div class="userNavigation__tabBtn">{{ $t('wallet.btn2') }}</div>
                        </a>
                        <a @click="openWithdraw">
                            <div class="userNavigation__tabBtn">{{ $t('wallet.btn3') }}</div>
                        </a>
                        <a @click="openHistory">
                            <div class="userNavigation__tabBtn">{{ $t('wallet.btn4') }}</div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <div class="deposit">
            <transition name="fade" appear mode="out-in">
                <div v-if="active.page == 0" key="deposit" class="deposit__box">
                    <div class="deposit__content-list">
                        <div class="deposit__content-item deposit__content-item-active">
                            <div class="deposit__content-title">{{ $t('wallet.text8') }}</div>
                            <div class="deposit__center-content">
                                <img class="deposit__qr-code" style="opacity: 1"
                                    :src="`https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${deposit.address}&choe=UTF-8`"
                                    alt="" v-if="deposit.active">
                                <img class="deposit__qr-code" style="opacity: 0.02"
                                    src='https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=test&choe=UTF-8' alt=""
                                    v-else>
                                <div class="deposit__text-items">
                                    <div class="deposit__text-item">
                                        {{ $t('wallet.text9') }}
                                    </div>
                                    <div class="deposit__text-item">
                                        {{ $t('wallet.text10', { sym: 'USDT TRC-20' }) }}
                                    </div>
                                    <div class="deposit__text-item">
                                        {{ $t('wallet.text11') }} 20 USD </div>
                                </div>
                            </div>
                            <div class="deposit__address__box" v-if="deposit.active">
                                <label style="width: 100%;">
                                    <input ref="address" class="deposit__address" type="text" :value="deposit.address"
                                        readonly="">
                                    <button class="deposit__address-btn" @click="copyAddress(deposit.address)">{{
                                        $t('wallet.btn5') }}
                                    </button>
                                </label>
                            </div>
                            <div class="deposit__address__box" v-else>
                                <label style="width: 100%;">
                                    <button class="buttonShowBlock" @click="getAddress()">{{
                                        $t('wallet.btn6') }}
                                    </button>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="deposit__content-list" style="height: 96px !important; margin-top: 43px !important;">
                        <div class="deposit__content-item deposit__content-item-active">
                            <div class="company__items">
                                <div class="marquee"
                                    style="border-radius: 10px;padding: 15px;display: flex;justify-content: center;height: 100%;">
                                    <ul
                                        style="display: flex;justify-content: flex-start;gap: 20px; padding: 0 40px; overflow: auto;">
                                        <li style="display: flex; justify-content: center"><img
                                                src="../../assets/images/company-images/Binance.svg" alt=""></li>
                                        <li style="display: flex; justify-content: center"><img
                                                src="../../assets/images/company-images/huobi.svg" alt=""></li>
                                        <li style="display: flex; justify-content: center"><img
                                                src="../../assets/images/company-images/Bitcoin.svg" alt=""></li>
                                        <li style="display: flex; justify-content: center"><img
                                                src="../../assets/images/company-images/bybit.svg" alt=""></li>
                                        <li style="display: flex; justify-content: center"><img
                                                src="../../assets/images/company-images/Investing.svg" alt=""></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="active.page == 1" key="withdraw" class="deposit__box">
                    <div class="deposit__content-list withdraw__container-content" style="min-height: 335px;">
                        <div class="withdraw__content-item withdraw__content-item-active">
                            <div class="withdraw__content-top">
                                <div class="deposit__content-title">{{ $t('wallet.text12') }}</div>
                                <div class="withdraw">
                                    <div class="withdraw__container">
                                        <div class="withdraw__address">
                                            <div class="withdraw__address-container">
                                                <div class="withdraw__address-title">
                                                    {{ $t('wallet.text13', { sym: 'USDT TRC-20' }) }}
                                                </div>
                                                <div class="withdraw__address-description">
                                                    {{ $t('wallet.text14') }}
                                                </div>
                                            </div>
                                            <!-- v-validate="{ required: true, regex: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/ }" -->
                                            <label>
                                                <input v-model="withdraw.address" class="withdraw__address-input"
                                                    type="text" name=address
                                                    
                                                    :placeholder="$t('wallet.text15', { sym: 'USDT TRC-20' })">
                                            </label>
                                        </div>
                                        <div class="withdraw__amount">
                                            <div class="withdraw__amount-container">
                                                <div class="withdraw__amount-title">{{
                                                    $t('wallet.text16') }}</div>
                                                <div class="withdraw__amount-description">{{
                                                    $t('wallet.text17') }} <span
                                                        @click="enterMaximumAmount(balance.available)"
                                                        style="border-bottom: 1px solid; cursor: pointer;">{{
                                                            balance.available | numeralFormat('0.00[000000]')
                                                        }}</span>
                                                    {{ 'USD' }}
                                                </div>
                                            </div>
                                            <label>
                                                <input v-model="withdraw.amount" class="withdraw__amount-input" type="text"
                                                    name="amount"
                                                    v-validate="{ required: true, regex: /^[0-9]\d*(\.\d+)?$/ }"
                                                    :placeholder="$t('wallet.text34') + ' USD'">
                                            </label>
                                        </div>
                                    </div>

                                    <div class="withdraw__btn-container">
                                        <a class="withdraw__btn" @click="sendWithdraw()">
                                            <img class="withdraw__btn-arrow" src="../../assets/img/withdraw-arrow.svg"
                                                alt="">
                                            {{ $t('wallet.btn7') }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="withdraw__content-bottom">
                                <div class="withdraw__info">
                                    <div class="deposit__content-title">
                                        {{ $t('wallet.text18') }}
                                    </div>
                                    <div class="withdraw__info-container">
                                        <div class="withdraw__info-text">
                                            {{ $t('wallet.text19') }}
                                        </div>
                                        <div class="withdraw__info-text">
                                            {{ $t('wallet.text20') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="active.page == 2" key="history" class="profile_card">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{{ $t('wallet.text21') }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="transaction-table">
                                <div class="table-responsive">
                                    <table class="table table-striped mb-0">
                                        <thead>
                                            <tr>
                                                <th>{{ $t('wallet.text22') }}</th>
                                                <th>{{ $t('wallet.text23') }}</th>
                                                <th>{{ $t('wallet.text24') }}</th>
                                                <th>{{ $t('wallet.text25') }}</th>
                                                <th>{{ $t('wallet.text26') }}</th>
                                                <th>{{ $t('wallet.text27') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!transactions.length">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{{ $t('wallet.text35') }}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr v-else-if="transactions" v-for="item in transactions" :key="item.txid">
                                                <!-- <td>{{ item.id }}</td> -->
                                                <td>{{ format_date(item.time) }}</td>
                                                <td style="color: white;">{{ item.btc |
                                                    numeralFormat('0.00[000000]') }} BTC</td>
                                                <td>{{ item.addr[0] }}</td>
                                                <td>{{
                                                    item.type == 0 ? $t('wallet.text28') : $t('wallet.text29')
                                                }}</td>
                                                <td :style="{ color: statusColor(item.status) }">{{
                                                    item.status == 0 ? $t('wallet.text30') :  
                                                    item.status == 1 ? $t('wallet.text30') :
                                                    item.status == 2 ? $t('wallet.text32') : item.status == 3 ?
                                                        $t('wallet.text31') : $t('wallet.text33')
                                                }}
                                                </td>
                                                <td style="color: white;">{{ item.usd |
                                                    numeralFormat('0.00[000000]') }} USD</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <br />
        <br />
    </fragment>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import CONFIG from '../../config/settings'
const API_URL = CONFIG.APIURL

export default {
    data() {
        return {
            active: {
                page: 0
            },
            deposit: {
                active: false,
                address: null
            },
            withdraw: {
                address: null,
                amount: null
            },
            promocode: '',
            transactions: null
        }
    },
    computed: {
        user() {
            return this.$store.state.auth.user
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        },
        balance() {
            return this.$store.state.balance.balance
        }
    },
    created() {
        if (!this.loggedIn)
            window.location.replace(CONFIG.BASEURL + '/signin')
    },
    methods: {
        activatePromo() {
            return axios.post(API_URL + 'auth/promocode', {
                id: JSON.parse(localStorage.getItem('token')),
                promocode: this.promocode
            }).then((response) => {
                this.$toast.success(response.data.message)
                this.promocode = null
            }).catch(err => {
                this.$toast.error(err.response.data.message)
            })
        },
        enterMaximumAmount(value) {
            this.withdraw.amount = value
        },
        copyAddress(value) {
            navigator.clipboard.writeText(value)
            this.$toast.info('Copied!')
        },
        statusColor(value) {
            if (value == 2) return 'rgb(93 120 255)'
            if (value == 3) return 'rgb(109 208 82)'
            if (value == 0) return 'rgb(239 113 36)'
            if (value == 1) return 'rgb(239 113 36)'
            else return 'rgb(254 36 31)'
        },
        sendWithdraw() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    this.$store.dispatch('balance/withdraw', { address: this.withdraw.address, amount: this.withdraw.amount }).then(
                        () => {
                            this.withdraw = {
                                address: null,
                                amount: null
                            }
                            this.$toast.success('Your withdrawal request created')
                        },
                        error => {
                            this.$toast.error(error.response.data)
                        }
                    )
                } else {
                    let address = this.errors.has('address')
                    let amount = this.errors.has('amount')
                    if (address)
                        this.warning('address')
                    else if (!address && amount)
                        this.warning('amount')
                }
            })
        },
        getAddress() {
            this.deposit.active = true
            this.deposit.address = this.user.wallet.address
        },
        openDeposit() {
            this.active.page = 0
        },
        openWithdraw() {
            this.deposit.active = false
            this.active.page = 1
        },
        openHistory() {
            this.deposit.active = false
            this.active.page = 2
            this.$store.dispatch('balance/transactions').then(
                data => {
                    this.transactions = data
                },
                error => {
                    this.$toast.error(error.response.data.message)
                }
            )
        },
        format_date(value) {
            if (value) {
                return moment.unix(value).format('DD/MM/YYYY hh:mm')
            }
        },
        warning(value) {
            if (this.errors.has(value))
                this.$toast.warning(this.errors.first(value))
        },
    },
}
</script>

<style scoped>
.userCard__container .menuProfile__container {
    padding: 0 !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}


.buttonShowBlock {
    height: 43px;
    background: rgb(60 103 227);
    background: linear-gradient(90deg, rgb(104 84 215) 0%, rgb(52 147 213) 100%);
    border-radius: 5px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    display: block;
    margin: auto;
}

.text-right a:hover {
    color: #5d78ff !important;
}

.page-title-content {
    color: #a1a0a7;
}

.page-title.dashboard {
    margin-left: 0 !important;
}

:root {
    --marquee-elements: 11;
    --marquee-width: 100vw;
    --marquee-height: 20vh;
    --marquee-elements-displayed: 7;
    --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
    --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}
</style>
